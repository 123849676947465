"use client";

import { Theme } from "@radix-ui/themes";

// This should be used only in the root of the app
function ICThemeWithPortal({
  children,
  appearance = "light",
}: {
  children: React.ReactNode;
  appearance?: "light" | "dark";
}) {
  return (
    <Theme
      accentColor="mint"
      appearance={appearance}
      style={{
        height: "100%",
        color: "var(--black)",
      }}
    >
      <div style={{ isolation: "isolate", height: "100%" }}>{children}</div>
      <div id="radix-themed-portal" style={{ isolation: "isolate" }} />
    </Theme>
  );
}

export { ICThemeWithPortal };
